import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home_Page from './components/home_page';
import About_Us from './components/about_us';
import Privacy_Policy from './components/privacy-policy';
import Terms_Of_Service from './components/terms-of-service';
import Not_Found from './components/not_found';

function App() {
  return (
    <Router> {/* Wrap your routes with Router */}
      <div>
        <Routes> 
          {/* Use `element` prop to render the component */}
          <Route path="/" element={<Home_Page />} />
          <Route path="about-us" element={<About_Us/>}/>
          <Route path="privacy-policy" element={<Privacy_Policy/>}/>
          <Route path="terms-of-service" element={<Terms_Of_Service/>}/>
          <Route path="*" element={<Not_Found/>}/> 
        </Routes>
      </div>
    </Router>
  );
}

export default App;
