import './css/about_us.css';  // Relative path to the CSS file
import { useNavigate } from 'react-router-dom';
function Privacy_Policy() {


const navigate = useNavigate();
return (
    <div className='privacy-full-page'>

        <div className='about-body-header'>
        <button className='about-home-button' onClick={() => navigate('/')}>Home</button>
     
     <a href="/about-us" className='about-privacy-policy'>About Us</a>
        </div>
<h1> Privacy Policy</h1>
        <div className='about-content'>
        <p>At Swift Chatbot, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our services.</p>
<p>We only collect data necessary to provide and improve our services, such as contact information and business-related details. We never sell or share your data with third parties without your consent, except as required by law.</p>
<p>Our team implements stringent security measures to ensure your data remains protected. Additionally, you have full control over your data and can request its deletion at any time.</p>
<p>Your trust is important to us, and we are dedicated to maintaining transparency and upholding your privacy. For any questions or concerns, please contact us directly.</p>

        </div>

    </div>
);



};
export default Privacy_Policy;