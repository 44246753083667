import './css/about_us.css';  // Relative path to the CSS file
import { useNavigate } from 'react-router-dom';
function About_Us() {


const navigate = useNavigate();
return (
    <div className='about-full-page'>

        <div className='about-body-header'>
        <button className='about-home-button' onClick={() => navigate('/')}>Home</button>
     
     <a href="/privacy-policy" className='about-privacy-policy'>Privacy Policy</a>
        </div>
<h1> About Us</h1>
        <div className='about-content'>
            <p>At Swift Chatbot, we specialize in helping small businesses enhance their customer service through AI-powered chatbots. Our solution is designed to integrate seamlessly with your website, providing 24/7 support to your customers with accurate and personalized responses.</p>
            <p> We believe in making advanced technology simple, affordable, and accessible, so you can focus on growing your business while we handle the customer conversations.</p>
            <p>Swift Chatbot is your partner in delivering exceptional service and building lasting customer relationships.</p>
            <p>Let us help you simplify and transform the way you connect with your customers.</p>
        </div>

    </div>
);



};
export default About_Us;