import './css/about_us.css';  // Relative path to the CSS file
import { useNavigate } from 'react-router-dom';
function Terms_Of_Service() {


const navigate = useNavigate();
return (
    <div className='terms-full-page'>

        <div className='about-body-header'>
        <button className='about-home-button' onClick={() => navigate('/')}>Home</button>
     
     <a href="/about-us" className='about-privacy-policy'>About Us</a>
        </div>
<h1> Terms Of Service</h1>
        <div className='about-content'>
        <p>Welcome to Swift Chatbot. By using our services, you agree to the following Terms of Service, which govern your use of our AI-powered chatbot solutions. Please read these terms carefully.</p>

<p>Our services are provided as-is, and we are not responsible for any disruptions, errors, or issues arising from the use of the chatbot. You agree to use our chatbot in accordance with all applicable laws and regulations. Any misuse or abuse of the services may result in suspension or termination of your account.</p>

<p>We reserve the right to modify or update these terms at any time. Any changes will be communicated to you, and your continued use of the services will indicate your acceptance of the updated terms.</p>

<p>If you have any questions or concerns regarding these Terms of Service, please contact us directly. We are committed to ensuring that your experience with Swift Chatbot is seamless and beneficial.</p>

        </div>

    </div>
);



};
export default Terms_Of_Service;