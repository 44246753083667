import './css/about_us.css';  // Relative path to the CSS file
import { useNavigate } from 'react-router-dom';
function Not_Found() {


const navigate = useNavigate();
return (
    <div className='notfound-full-page'>

        <div className='about-body-header'>
        <button className='about-home-button' onClick={() => navigate('/')}>Home</button>
     
     <a href="/about-us" className='about-privacy-policy'>About Us</a>
        </div>
<h1> Page Not Found!</h1>

    </div>
);



};
export default Not_Found;